<template>
  <div class="local minbox">
    <!-- 头部通用区域 -->
    <div class="boxContent">
      <!-- 头部导航区域 -->
      <div class="navbar ">
        <Navbar :itemIndex="activeIndex" />
      </div>
    </div>
    <!--    banner-->
    <section class="solution-banner">
      <div class="main-container">
        <div class="inner">
          <div class="title1">
            首创去中心化的工业互联网产业金融模式
          </div>
          <div class="title2">
            The first decentralized industrial Internet industry financial model
          </div>
        </div>
      </div>
    </section>

    <section class="main-container">
      <div class="section-name">
        金融模式介绍
      </div>

      <div class="section-des txt-center">
        华炫鼎盛以金融科技为手段助力国家打造工业互联网产业新生态，助力中国加速实现科技高质量发展，华炫鼎盛以全新理念创新了以数据提升企业资信水平、以数据驱动资金有效投放的新型场景金融模式。公司目前已成功打造跨境供应链采购、液化天然气供应链采购等多种融资场景，为金融机构开拓了传统融资模式无法触达的客户渠道，为产业发展注入金融血液。
      </div>
      <div class="show-img">
        <img src="../../assets/solutionImg/initial.png" alt="">
      </div>

      <div class="intro">
        <div>
          <div class="intro-name">特点</div>
          <section>
            <div>
              <span class="num">1</span><span class="w439">不依赖核心企业</span>
            </div>
            <div>
              <span class="num">2</span><span class="w439">通过数据为企业增信</span>
            </div>
            <div>
              <span class="num">3</span><span class="w439">以数据提升企业资信水平、以数据驱动资金有效投放的新型场景金融模式优势</span>
            </div>
          </section>
        </div>
        <div class="column">
          <div class="intro-name">优势</div>
          <div class="w439">
            为金融机构开拓了传统融资模式无法触达的客户渠道，为产业发展注入金融血液。
          </div>
        </div>
      </div>
    </section>

    <!-- 底部公共区域 -->
    <section class="boxContentFooter">
      <FooterAll />
    </section>
  </div>
</template>
<script>
  import Navbar from '../../components/public/NavBar'
  import FooterAll from '../../components/public/footerAll'
  export default {
    data(){
      return{
        activeIndex: '1'
      }
    },
    components: {
      Navbar,
      FooterAll,
    },
  }
</script>
<style lang="scss" scoped>
  @import "./solution.scss";
</style>
<style lang="scss" scoped>
  .w439{
    width: 439px;
    font-size: 14px;
    color: #333333;
    line-height: 24px;
    display: inline-block;
    vertical-align: top;
  }
  .column{
    margin-left: 65px;
  }
  .show-img{
    width: 802px;
    height: 802px;
    margin: 59px auto 38px;
    img{
      width: 100%;
    }
  }
  .local{
    .solution-banner{
      background-image: url("../../assets/solutionImg/initial_banner.png");
    }
  }
  .intro{
    display: flex;
    padding-left: 100px;
    padding-bottom: 64px;
    .intro-name{
      font-size: 32px;
      color: #1B314E;
      line-height: 42px;
      margin-bottom: 30px;
    }
    .num{
      width: 20px;
      height: 20px;
      background: #4A8ABE;
      border-radius: 50%;
      display: inline-block;
      margin-right: 20px;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 20px;
      text-align: center;
      margin-bottom: 25px;
    }
  }
</style>
